import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter, useHistory } from "react-router-dom";
import classNames from "classnames";
import usePrevious from "hooks/usePrevious";
import LabreportModal from "modules/consultation-list/modals/LabreportModal";
import headerCall from "assets/img/headerCaller.svg";
import callIcon from "assets/img/call-icon.svg";

const Header = (props) => {
  const { location } = props;
  const pathName = location.pathname;
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const [labModal, setLabModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const previousPath = usePrevious(location.pathname);

  useEffect(() => {
    if (toggle && previousPath !== location.pathname) setToggle(false);
    // setToggle(a => !a)
  }, [location.pathname, previousPath, toggle]);

  const handleOpen = () => {
    setLabModal(true);
  };

  const handleScroll = () => {
    // Calculate the scroll position as a percentage of the page height
    const scrollPosition =
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
      100;

    // Update the visibility of the button based on the scroll position
    setIsVisible(scrollPosition <= 50);
  };

  useEffect(() => {
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header>
      <div className="nav-wrapper">
        <div className="container-header">
          <div className="logo-div">
            <Link to="/">
              {/* <img className="lazy" src={`${process.env.REACT_APP_BASE_URL}/resources/img/sabinehospital-logo.svg`} alt="" /> */}
              <img className="logo" src="/img/logo-new.svg" alt="" />
            </Link>
          </div>
          <div className="header-call">
            <div className="phone-wrap">
              <a href="tel:0485-2838100">
                <img
                  style={{ width: "16px", height: "16px", margin: "0 0 1px 0" }}
                  src={callIcon}
                  alt="call"
                />
              </a>
              <a href="tel:0485-2838100">
                <h1 className="phone-number" style={{ marginRight: "12px" }}>
                  0485 - 2838100
                </h1>
              </a>
            </div>

            <button
              type="button"
              className="header-btn"
              onClick={handleOpen}
              style={{ marginLeft: "0px" }}
            >
              Lab Report
            </button>

            <button
              onClick={() => history.push("/consultation")}
              type="button"
              className="header-btn"
            >
              Book an Appointment
            </button>
            <div className="container2 menu-container-mobile">
              <nav className={classNames("menu", { "menu-active": toggle })}>
                <div
                  className="burger-close"
                  onClick={() => setToggle((a) => !a)}
                >
                  <img src="/img/burger-close.svg" />
                </div>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/services">Services</NavLink>
                <NavLink to="/doctors">Doctors</NavLink>
                <NavLink to="/blogs">What’s New</NavLink>
                <NavLink to="/testimonials">Testimonials</NavLink>
                <NavLink to="/success-stories">Success Stories</NavLink>
                <NavLink to="/careers">Careers</NavLink>
                <NavLink to="/gallery">Gallery</NavLink>
                <NavLink to="/contact-us">Contact Us</NavLink>
                {toggle && (
                  <NavLink to="/consultation">Book an Appointment</NavLink>
                )}
                {toggle && (
                  <div
                    to="/"
                    onClick={handleOpen}
                    className=""
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: 500,
                      width: "80%",
                      textAlign: "left",
                    }}
                  >
                    Lab Report
                  </div>
                )}
                {/* {toggle && (<NavLink to="/" onClick={handleOpen}>Lab Report</NavLink>)} */}
                <div className="nav-phone-email">
                  <div className="nav-contact">
                    <img src="/img/phone-icon.svg" />
                    <h4>
                      <a href="tel:0485-2838100">0485-2838100</a>
                    </h4>
                  </div>
                  <div className="nav-mail">
                    <img src="/img/mail-icon.svg" />
                    <h4>
                      <a href="mailto:feedback@sabinehospital.com">
                        feedback@sabinehosptial.com
                      </a>
                    </h4>
                  </div>
                </div>
              </nav>
              {/* <div
            role="presentation"
            onClick={() => setToggle(a => !a)}
            className={classNames('toggle-btn', { 'active-toggle': toggle })}
          >
            <span className="toggle-lines toggle-close-lines" />
          </div> */}
              <div onClick={() => setToggle((a) => !a)} className="burger-menu">
                <img src="/img/burger-menu.svg" />
              </div>
            </div>
          </div>
          {/* <nav className={classNames('menu', { 'menu-active': toggle })}>
            <NavLink to="/">Home</NavLink>
            <NavLink to="/services">Services</NavLink>
            <NavLink to="/doctors">Doctors</NavLink>
            <NavLink to="/blogs">What’s New</NavLink>
            <NavLink to="/testimonials">Testimonials</NavLink>
            <NavLink to="/success-stories">Success Stories</NavLink>
            <NavLink to="/careers">Careers</NavLink>
            <NavLink to="/gallery">Gallery</NavLink>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </nav> */}
        </div>
        <div className="container2">
          <nav className={classNames("menu", { "menu-active": toggle })}>
            <NavLink className={pathName == "/" ? "selected-menu" : ""} to="/">
              Home
            </NavLink>
            <NavLink
              className={pathName == "/services" ? "selected-menu" : ""}
              to="/services"
            >
              Services
            </NavLink>
            <NavLink
              className={pathName == "/doctors" ? "selected-menu" : ""}
              to="/doctors"
            >
              Doctors
            </NavLink>
            <NavLink
              className={pathName == "/blogs" ? "selected-menu" : ""}
              to="/blogs"
            >
              What’s New
            </NavLink>
            <NavLink
              className={pathName == "/testimonials" ? "selected-menu" : ""}
              to="/testimonials"
            >
              Testimonials
            </NavLink>
            <NavLink
              className={pathName == "/success-stories" ? "selected-menu" : ""}
              to="/success-stories"
            >
              Success Stories
            </NavLink>
            <NavLink
              className={pathName == "/careers" ? "selected-menu" : ""}
              to="/careers"
            >
              Careers
            </NavLink>
            <NavLink
              className={pathName == "/gallery" ? "selected-menu" : ""}
              to="/gallery"
            >
              Gallery
            </NavLink>
            <NavLink
              className={pathName == "/contact-us" ? "selected-menu" : ""}
              to="/contact-us"
            >
              Contact Us
            </NavLink>
            {toggle && (
              <NavLink to="/consultation">Book an Appointment</NavLink>
            )}
            {toggle && (
              <div
                to="/"
                onClick={handleOpen}
                className=""
                style={{ color: "#fff", fontSize: "18px" }}
              >
                Lab Report
              </div>
            )}
            {/* {toggle && (<NavLink to="/" onClick={handleOpen}>Lab Report</NavLink>)} */}
          </nav>
          <div
            role="presentation"
            onClick={() => setToggle((a) => !a)}
            className={classNames("toggle-btn", { "active-toggle": toggle })}
          >
            <span className="toggle-lines" />
          </div>
        </div>
      </div>
      <LabreportModal labModal={labModal} setLabModal={setLabModal} />
      {!toggle && window.location.pathname !== "/consultation" ? (
        <div
          className={`appointment-report ${
            isVisible ? "visible-button" : "hidden-button"
          }`}
        >
          <button className="lab-rep-button" onClick={handleOpen}>
            Lab Report
          </button>
          <button
            onClick={() => history.push("/consultation")}
            className="book-app-button"
          >
            Book an Appointment
          </button>
        </div>
      ) : (
        ""
      )}
    </header>
  );
};

export default withRouter(Header);
